import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ggDTO} from 'gg-dto-validator';
import {ILoginResponse} from '../models/login-response.interface';
import {TokenService} from './token.service';
import {SetLoginState} from '../store/actions/authentication.actions';
import {Router} from '@angular/router';

@Injectable()
export class AuthenticationService {

  constructor(private readonly _httpClient: HttpClient,
              private readonly _store: Store,
              private readonly _router: Router,
              private readonly _tokenService: TokenService,
  ) {
  }

  private _loginHandler(token: string, refToken: string): void {
    this._tokenService.setToken(token);
    this._tokenService.setRefToken(refToken);

    this._store.dispatch(new SetLoginState(true));
  }

  login(email: string, password: string): Observable<ILoginResponse> {
    return this._httpClient.post<ILoginResponse>(`${environment.api_url}/users/login`, {email, password})
      .pipe(tap(response => {
        this._loginHandler(response.auth.token, response.auth.refresh_token);
      }));
  }

  private _refreshToken(token: string, refToken: string): void {
    this.logout();

    // this._httpClient.post(`${environment.api_url}/auth/refresh`, {token, refToken})
    //   .subscribe(tap((response: CredentialsInterface) => {
    //     this._loginHandler(response.token, response.refToken);
    //   }), error => {
    //     this.logout();
    //   });
  }

  logout(): void {
    this._tokenService.removeToken();
    this._tokenService.removeRefToken();
  }

  autologin(): void {
    const token = this._tokenService.getToken();

    // Check if token exist
    if (!token) {
      return this.logout();
    }

    const tokenData = this._tokenService.getAuthTokenData(token);
    const refToken = this._tokenService.getRefToken();

    if (tokenData.expireDate && refToken) {
      // Check if token expired
      if (tokenData.expireDate.getTime() <= new Date().getTime()) {

        return this._refreshToken(token, refToken);
      }

      this._loginHandler(token, refToken);
    } else {
      return this.logout();
    }
  }
}
