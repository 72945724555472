import {Routes} from '@angular/router';

import {FullComponent} from './layouts/full/full.component';
import {AuthGuard} from './guards/auth.guard';

export const AppRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./modules/authentication/authentication.module').then(c => c.AuthenticationModule)
  },
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'material',
        loadChildren: () => import('./material-component/material.module').then(m => m.MaterialComponentsModule)
      },
      {
        path: 'starter',
        loadChildren: () => import('./starter/starter.module').then(m => m.StarterModule)
      },
      {
        path: 'customers',
        loadChildren: () => import('./modules/customers/customers.module').then(m => m.CustomersModule)
      },
      {
        path: 'sellers',
        loadChildren: () => import('./modules/sellers/sellers.module').then(m => m.SellersModule)
      },
      {
        path: 'producers',
        loadChildren: () => import('./modules/producers/producers.module').then(m => m.ProducersModule)
      },
      {
        path: 'categories',
        loadChildren: () => import('./modules/categories/categories.module').then(m => m.CategoriesModule)
      },
      {
        path: 'products',
        loadChildren: () => import('./modules/products/products.module').then(m => m.ProductsModule)
      },
      {
        path: 'orders',
        loadChildren: () => import('./modules/orders/orders.module').then(m => m.OrdersModule)
      },
      {
        path: 'import',
        loadChildren: () => import('./modules/import/import.module').then(m => m.ImportModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule)
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/auth',
      },
    ]
  }
];
